import { useAuth } from "../Contexts/AuthContext";

import { useState, useEffect, createRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { firestore } from "../firebase";
// import _ from 'lodash';
// import isEqual from 'lodash/isEqual';
// import { isEqual } from 'lodash';

import {
  Dimmer,
  Dropdown,
  Grid,
  Image,
  Loader,
  Menu,
  Icon,
  Button,
  Form,
} from "semantic-ui-react";

import {
  GoogleMap,
  LoadScript,
  Marker,
  KmlLayer,
  InfoBox,
} from "@react-google-maps/api";

import { Pannellum } from "pannellum-react";

import axios from "axios";

const MapScreen = (props) => {
  let anSVGPathString =
    "M3.93799 3.33349L68.9589 19.2514C69.1899 19.308 69.3491 19.5194 69.3396 19.7571L69.2335 22.4095L68.4498 27.6186L67.9258 29.4438C67.3885 31.3148 66.6939 33.1369 65.8494 34.8908L65.061 36.5279L62.7291 41.08L60.1255 44.7879L54.7002 51.062C53.5774 52.3604 52.3594 53.5731 51.0562 54.6902L45.224 59.6892L43.1052 61.243C41.752 62.2353 40.3283 63.1276 38.8452 63.9127L36.3618 65.2274L34.6469 66.0175C33.0576 66.7496 31.4149 67.3598 29.733 67.8427L27.6186 68.4498L22.4096 69.2335L19.7571 69.3396C19.5194 69.3491 19.308 69.1899 19.2514 68.9589L3.33343 3.93805C3.24418 3.5735 3.57344 3.24425 3.93799 3.33349Z";

  const history = useHistory();

  const [centerCoordinate, setCenterCoordinate] = useState({
    lat: 3.13,
    lng: 101.6869,
  });

  const pannellumRef = createRef();

  const [marker, setMarker] = useState(false);

  const [currentRotationValue, setCurrentRotationValue] = useState();

  const Coordinate = require("coordinate-parser");

  const [libraries] = useState(["places"]);

  const [gridId, setGridId] = useState("N86E36");
  const [imageNumber, setImageNumber] = useState("000001");

  const [currentCoordinate, setCurrentCoordinate] = useState({});

  const [shapeFile, setShapeFile] = useState("");

  const [searchBox, setSearchBox] = useState("");

  const [isAdmin, setIsAdmin] = useState(false);

  // const [isBigGrid, setIsBigGrid] = useState(true); //////////////////////////////////////// focus here

  const [table, setTable] = useState([]);

  const [selectedTable, setSelectedTable] = useState([]);
  const [tableLoadingState, setTableLoadingState] = useState(false);

  const [activeTracklog, setActiveTracklog] = useState("");
  const [tracklogOne, setTracklogOne] = useState([]);
  const [tracklogTwo, setTracklogTwo] = useState([]);
  const [tracklogThree, setTracklogThree] = useState([]);

  const { currentUser, logout } = useAuth();



  // const gridFive = [
  //   {
  //     name: "N92E43",
  //     lng: 101.566980078853,
  //     lat: 2.6257141229016,
  //   },
  //   {
  //     name: "N92E44",
  //     lng: 101.611937309209,
  //     lat: 2.62582027841003,
  //   },
  //   {
  //     name: "N92E46",
  //     lng: 101.701853749562,
  //     lat: 2.62602623975911,
  //   },
  //   {
  //     name: "N91E40",
  //     lng: 101.431999154577,
  //     lat: 2.67059264742756,
  //   },
  //   {
  //     name: "N91E42",
  //     lng: 101.521914438863,
  //     lat: 2.67081710866846,
  //   },
  //   {
  //     name: "N91E43",
  //     lng: 101.566873123081,
  //     lat: 2.67092608451787,
  //   },
  //   {
  //     name: "N91E44",
  //     lng: 101.611832478016,
  //     lat: 2.67103290815126,
  //   },
  //   {
  //     name: "N93E43",
  //     lng: 101.567086350764,
  //     lat: 2.58050263752336,
  //   },
  //   {
  //     name: "N93E44",
  //     lng: 101.612041474533,
  //     lat: 2.58060811156068,
  //   },
  //   {
  //     name: "N93E45",
  //     lng: 101.656997277336,
  //     lat: 2.58071148264534,
  //   },
  //   {
  //     name: "N89E37",
  //     lng: 101.296889426986,
  //     lat: 2.76065625879384,
  //   },
  //   {
  //     name: "N89E38",
  //     lng: 101.34184894845,
  //     lat: 2.7607777064814,
  //   },
  //   {
  //     name: "N89E39",
  //     lng: 101.386809204385,
  //     lat: 2.76089689983396,
  //   },
  //   {
  //     name: "N89E40",
  //     lng: 101.431770176777,
  //     lat: 2.76101385205481,
  //   },
  //   {
  //     name: "N89E41",
  //     lng: 101.476731847612,
  //     lat: 2.76112857635299,
  //   },
  //   {
  //     name: "N89E42",
  //     lng: 101.521694198877,
  //     lat: 2.76124108594326,
  //   },
  //   {
  //     name: "N89E43",
  //     lng: 101.566657212561,
  //     lat: 2.7613513940461,
  //   },
  //   {
  //     name: "N89E44",
  //     lng: 101.611620870655,
  //     lat: 2.7614595138876,
  //   },
  //   {
  //     name: "N89E45",
  //     lng: 101.656585155149,
  //     lat: 2.76156545869944,
  //   },
  //   {
  //     name: "N89E47",
  //     lng: 101.746515531307,
  //     lat: 2.76177087618857,
  //   },
  //   {
  //     name: "N89E48",
  //     lng: 101.791481586959,
  //     lat: 2.76187037535669,
  //   },
  //   {
  //     name: "N91E47",
  //     lng: 101.746714387152,
  //     lat: 2.67134059808723,
  //   },
  //   {
  //     name: "N91E48",
  //     lng: 101.791676244988,
  //     lat: 2.67143894521573,
  //   },
  //   {
  //     name: "N90E39",
  //     lng: 101.386926262237,
  //     lat: 2.7156867635228,
  //   },
  //   {
  //     name: "N90E41",
  //     lng: 101.476844492269,
  //     lat: 2.71591703100757,
  //   },
  //   {
  //     name: "N90E42",
  //     lng: 101.52180465665,
  //     lat: 2.71602886311892,
  //   },
  //   {
  //     name: "N90E43",
  //     lng: 101.566765496599,
  //     lat: 2.71613851176753,
  //   },
  //   {
  //     name: "N90E44",
  //     lng: 101.611726994112,
  //     lat: 2.71624599017886,
  //   },
  //   {
  //     name: "N90E45",
  //     lng: 101.656689131185,
  //     lat: 2.71635131158403,
  //   },
  //   {
  //     name: "N90E46",
  //     lng: 101.701651889816,
  //     lat: 2.71645448921951,
  //   },
  //   {
  //     name: "N90E47",
  //     lng: 101.746615252004,
  //     lat: 2.71655553632737,
  //   },
  //   {
  //     name: "N90E48",
  //     lng: 101.791579199749,
  //     lat: 2.71665446615505,
  //   },
  //   {
  //     name: "N87E35",
  //     lng: 101.206718223552,
  //     lat: 2.85082239420206,
  //   },
  //   {
  //     name: "N87E36",
  //     lng: 101.251680807403,
  //     lat: 2.85094992144766,
  //   },
  //   {
  //     name: "N87E37",
  //     lng: 101.296644135525,
  //     lat: 2.85107513190426,
  //   },
  //   {
  //     name: "N87E38",
  //     lng: 101.34160818989,
  //     lat: 2.85119803876445,
  //   },
  //   {
  //     name: "N87E40",
  //     lng: 101.43153840524,
  //     lat: 2.85143699449562,
  //   },
  //   {
  //     name: "N87E41",
  //     lng: 101.476504530173,
  //     lat: 2.85155306978123,
  //   },
  //   {
  //     name: "N87E43",
  //     lng: 101.566438724439,
  //     lat: 2.85177848127269,
  //   },
  //   {
  //     name: "N87E44",
  //     lng: 101.611406757727,
  //     lat: 2.85188784392774,
  //   },
  //   {
  //     name: "N87E46",
  //     lng: 101.701344606514,
  //     lat: 2.85209994922188,
  //   },
  //   {
  //     name: "N87E47",
  //     lng: 101.746314385975,
  //     lat: 2.85220271834384,
  //   },
  //   {
  //     name: "N88E37",
  //     lng: 101.296767150934,
  //     lat: 2.80586543842078,
  //   },
  //   {
  //     name: "N88E38",
  //     lng: 101.341728929838,
  //     lat: 2.80598762236063,
  //   },
  //   {
  //     name: "N88E39",
  //     lng: 101.386691430085,
  //     lat: 2.80610753393555,
  //   },
  //   {
  //     name: "N88E40",
  //     lng: 101.431654633656,
  //     lat: 2.80622518634937,
  //   },
  //   {
  //     name: "N88E41",
  //     lng: 101.476618522531,
  //     lat: 2.80634059281172,
  //   },
  //   {
  //     name: "N88E42",
  //     lng: 101.521583078693,
  //     lat: 2.80645376653797,
  //   },
  //   {
  //     name: "N88E44",
  //     lng: 101.611514120807,
  //     lat: 2.80667346867223,
  //   },
  //   {
  //     name: "N88E45",
  //     lng: 101.656480570729,
  //     lat: 2.80678002353935,
  //   },
  //   {
  //     name: "N88E47",
  //     lng: 101.746415238239,
  //     lat: 2.80698660706321,
  //   },
  //   {
  //     name: "N88E48",
  //     lng: 101.791383419802,
  //     lat: 2.80708666221227,
  //   },
  //   {
  //     name: "N84E37",
  //     lng: 101.296270784391,
  //     lat: 2.98670718950574,
  //   },
  //   {
  //     name: "N84E38",
  //     lng: 101.341241773325,
  //     lat: 2.98683218512657,
  //   },
  //   {
  //     name: "N84E40",
  //     lng: 101.431185739619,
  //     lat: 2.9870751560301,
  //   },
  //   {
  //     name: "N84E41",
  //     lng: 101.476158680894,
  //     lat: 2.98719315773165,
  //   },
  //   {
  //     name: "N8E43",
  //     lng: 101.566106389492,
  //     lat: 2.98742225972547,
  //   },
  //   {
  //     name: "N86E36",
  //     lng: 101.251554759149,
  //     lat: 2.89615939056463,
  //   },
  //   {
  //     name: "N86E37",
  //     lng: 101.296520393883,
  //     lat: 2.89628532864536,
  //   },
  //   {
  //     name: "N86E39",
  //     lng: 101.386453784674,
  //     lat: 2.89653025310697,
  //   },
  //   {
  //     name: "N86E42",
  //     lng: 101.521358903692,
  //     lat: 2.89688045862518,
  //   },
  //   {
  //     name: "N86E43",
  //     lng: 101.566328546667,
  //     lat: 2.89699266501246,
  //   },
  //   {
  //     name: "N86E45",
  //     lng: 101.656269629405,
  //     lat: 2.8972103639694,
  //   },
  //   {
  //     name: "N86E46",
  //     lng: 101.701241033119,
  //     lat: 2.89731588301236,
  //   },
  //   {
  //     name: "N86E48",
  //     lng: 101.791185475118,
  //     lat: 2.89752032645232,
  //   },
  //   {
  //     name: "N85E38",
  //     lng: 101.341364598504,
  //     lat: 2.94162033074696,
  //   },
  //   {
  //     name: "N85E39",
  //     lng: 101.386333939833,
  //     lat: 2.94174231697566,
  //   },
  //   {
  //     name: "N85E41",
  //     lng: 101.476274596207,
  //     lat: 2.94197936284335,
  //   },
  //   {
  //     name: "N85E44",
  //     lng: 101.611190244528,
  //     lat: 2.94231781343205,
  //   },
  //   {
  //     name: "N90E40",
  //     lng: 101.431885021463,
  //     lat: 2.7158030022136,
  //   },
  //   {
  //     name: "N87E39",
  //     lng: 101.38657295247,
  //     lat: 2.85131865522677,
  //   },
  //   {
  //     name: "N87E42",
  //     lng: 101.521471309247,
  //     lat: 2.85166689429967,
  //   },
  //   {
  //     name: "N87E45",
  //     lng: 101.656375391092,
  //     lat: 2.85199499549792,
  //   },
  //   {
  //     name: "N87E48",
  //     lng: 101.79128471146,
  //     lat: 2.85230331611349,
  //   },
  //   {
  //     name: "N88E43",
  //     lng: 101.566548284123,
  //     lat: 2.80656472074922,
  //   },
  //   {
  //     name: "N88E46",
  //     lng: 101.701447615877,
  //     lat: 2.80688439858854,
  //   },
  //   {
  //     name: "N92E45",
  //     lng: 101.656895205442,
  //     lat: 2.62592431295074,
  //   },
  //   {
  //     name: "N91E39",
  //     lng: 101.387042590509,
  //     lat: 2.670477135603,
  //   },
  //   {
  //     name: "N91E41",
  //     lng: 101.476956443361,
  //     lat: 2.67070596737814,
  //   },
  //   {
  //     name: "N91E45",
  //     lng: 101.656792485671,
  //     lat: 2.67113759279908,
  //   },
  //   {
  //     name: "N93E46",
  //     lng: 101.701953741188,
  //     lat: 2.5808127640121,
  //   },
  //   {
  //     name: "N84E39",
  //     lng: 101.386213431082,
  //     lat: 2.98695483623254,
  //   },
  //   {
  //     name: "N84E42",
  //     lng: 101.521132236865,
  //     lat: 2.98730885455543,
  //   },
  //   {
  //     name: "N86E35",
  //     lng: 101.206589855569,
  //     lat: 2.8960311176577,
  //   },
  //   {
  //     name: "N86E38",
  //     lng: 101.341486741736,
  //     lat: 2.8964089450931,
  //   },
  //   {
  //     name: "N86E41",
  //     lng: 101.476389883683,
  //     lat: 2.89676599665919,
  //   },
  //   {
  //     name: "N86E44",
  //     lng: 101.61129879458,
  //     lat: 2.89710262904927,
  //   },
  //   {
  //     name: "N86E47",
  //     lng: 101.746212987697,
  //     lat: 2.89741919942315,
  //   },
  //   {
  //     name: "N85E37",
  //     lng: 101.29639593913,
  //     lat: 2.94149601804538,
  //   },
  //   {
  //     name: "N85E40",
  //     lng: 101.431303945079,
  //     lat: 2.94186198993728,
  //   },
  //   {
  //     name: "N85E43",
  //     lng: 101.566217763965,
  //     lat: 2.94220726136465,
  //   },
  //   {
  //     name: "N89E46",
  //     lng: 101.701550048036,
  //     lat: 2.76166924171887,
  //   },
  //   {
  //     name: "N91E46",
  //     lng: 101.701753128048,
  //     lat: 2.67124015169728,
  //   },
  //   {
  //     name: "N84E44",
  //     lng: 101.611081120738,
  //     lat: 2.98753338647154,
  //   },
  //   {
  //     name: "N86E40",
  //     lng: 101.431421504667,
  //     lat: 2.89664926589204,
  //   },
  //   {
  //     name: "N85E42",
  //     lng: 101.52124587518,
  //     lat: 2.9420944489114,
  //   },
  // ];

  const shapes = [
    // {
    //   key: 0,
    //   text: "Grid 5 by 5",
    //   value: "Grid5KM.kml",
    // },
    // {
    //   key: 1,
    //   text: "Submit Bounder",
    //   value: "Submit_Boundary.kml",
    // },
    // {
    //   key: 2,
    //   text: "submit boundary BS 210707",
    //   value: "submit_boundary_BS_210707.kml",
    // },
    // {
    //   key: 3,
    //   text: "lvsj grid 5km",
    //   value: "lvsj_grid5km.kml",
    // },
    {
      key: 0,
      text: "5x5 kulai kml",
      value: "5x5-kulai.kml",
    },
  ];

  const addZero = (num, places) =>
    places <= 4
      ? String(num).padStart(6, "0")
      : String(num).padStart(places, "0");

  const getImage = (tracklog, lat, lon) => {
    const imageName = tracklog
      .filter((item) => item.latitude === lat && item.longitude === lon)
      .map((item) => item.filename);
    setGridId(imageName[0].split("-")[0]);
    setImageNumber(imageName[0].split("-")[1]);

  };
  console.log(imageNumber)

  const getNextImage = () => {
    const countZero = (imageNumber.match(/0/g) || []).length + 1;
    const nextNumber = parseInt(imageNumber) + 1;
    const newImageNumber = addZero(nextNumber, countZero);
    console.log(countZero)
    const imageTitle = gridId + "-" + newImageNumber;
    console.log(imageTitle)

    const imageLatitude = activeTracklog
      .filter((item) => item.filename === imageTitle)
      .map((item) => item.latitude);
    const imageLongitude = activeTracklog
      .filter((item) => item.filename === imageTitle)
      .map((item) => item.longitude);

    setCurrentCoordinate({
      lat: parseFloat(imageLatitude[0]),
      lng: parseFloat(imageLongitude[0]),
    });

    setImageNumber(newImageNumber);
  };

  const getPreviousImage = () => {
    const countZero = (imageNumber.match(/0/g) || []).length + 1;
    const previousNumber = parseInt(imageNumber) - 1;
    const newImageNumber = addZero(previousNumber, countZero);
    const imageTitle = gridId + "-" + newImageNumber;

    const imageLatitude = activeTracklog
      .filter((item) => item.filename === imageTitle)
      .map((item) => item.latitude);
    const imageLongitude = activeTracklog
      .filter((item) => item.filename === imageTitle)
      .map((item) => item.longitude);

    setCurrentCoordinate({
      lat: parseFloat(imageLatitude[0]),
      lng: parseFloat(imageLongitude[0]),
    });

    setImageNumber(newImageNumber);
  };

  const handleTable = (event, data) => {
    if (data.value.length <= 3) {
      setSelectedTable(data.value);
      if (setSelectedTable(data.value)) {
        selectedTable.pop()
        console.log(selectedTable)
      }
      // selectedTable.pop()
    }
  };

  const handleCoordinate = () => {
    var position = new Coordinate(searchBox.toString());
    setCenterCoordinate({
      lat: position.getLatitude(),
      lng: position.getLongitude(),
    });
    setMarker(true);
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push("/login");
    } catch { }
  };

  const fetchTracklog = () => {
    firestore
      .collection("tracklogs")
      .orderBy("text", "asc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setTable(data);
      });
  };

  const checkAdmin = (email) => {
    firestore
      .collection("users")
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        if (data.length > 0) {
          setIsAdmin(true);
        }
      });
  };

  checkAdmin(currentUser.email);

  // const listOfBigGrids = [
  //   "Grid_N84E39_Final",
  //   "Grid_N84E40_Final",
  //   "Grid_N84E42_Final", // 15,000
  //   "Grid_N85E40_Final",
  //   "Grid_N85E41_Final",
  //   "Grid_N85E42_Final",
  //   "Grid_N85E43_Final", // 15,000
  //   "Grid_N86E40_Final",
  //   "Grid_N86E41_Final",
  //   "Grid_N86E42_Final",
  //   "Grid_N87E39_Final", // 15,000
  //   "Grid_N87E40_Final",
  //   "Grid_N87E41_Final",
  //   "Grid_N87E42_Final",
  //   "Grid_N87E43_Final",
  //   "Grid_N87E44_Final",
  //   "Grid_N87E45_Final",
  //   "Grid_N87E46_Final",
  //   "Grid_N88E39_Final", // 15,000
  //   "Grid_N88E40_Final",
  //   "Grid_N88E41_Final",
  //   "Grid_N88E42_Final",
  //   "Grid_N88E44_Final",
  //   "Grid_N88E45_Final",
  //   "Grid_N88E46_Final",
  //   "Grid_N89E40_Final",
  //   "Grid_N90E41_Final",
  //   "Grid_N90E42_Final", 
  //   "Grid_N90E45_Final",
  //   "Grid_N91E42_Final", // 15,000
  //   "Grid_N91E43_Final",
  //   "Grid_N91E45_Final",
  //   "Grid_N91E46_Final",
  //   "Grid_N92E45_Final", // 15,000

  //   ];
  // const isGridExist = listOfBigGrids
  //   .filter((item) => item.name === selectedTable[0]);

  // const isGridExist = _.intersectionWith(selectedTable, listOfBigGrids, _.isEqual);

  // console.log(isGridExist)

  // DefaultImageCoordinates({
  //   lat: 2.862045,
  //   lng: 101.296612,
  // });
  const defaultImageCoordinates = {
    lat: 2.862045,
    lng: 101.296612,
  };
  let setPosition = ""

  if (selectedTable.length > 0) {
    setPosition = currentCoordinate
  } else if (selectedTable.length < 1) {
    setPosition = defaultImageCoordinates
  } else {
    setPosition = ""
  }



  useEffect(() => {
    if (selectedTable.length === 3) {
      setTableLoadingState(true);
      axios
        .all([
          axios.get(
            // `https://serene-mountain-44627.herokuapp.com/https://tnb.urbanexplorer.com.my:7796/tracklogs/${selectedTable[0]}`
            `https://serene-mountain-44627.herokuapp.com/https://dbnsc.urbanstreetviewer.my/tracklogs/${selectedTable[0]}`
          ),
          axios.get(
            // `https://serene-mountain-44627.herokuapp.com/https://tnb.urbanexplorer.com.my:7796/tracklogs/${selectedTable[1]}`
            `https://serene-mountain-44627.herokuapp.com/https://dbnsc.urbanstreetviewer.my/tracklogs/${selectedTable[1]}`
          ),
          axios.get(
            // `https://serene-mountain-44627.herokuapp.com/https://tnb.urbanexplorer.com.my:7796/tracklogs/${selectedTable[2]}`
            `https://serene-mountain-44627.herokuapp.com/https://dbnsc.urbanstreetviewer.my/tracklogs/${selectedTable[2]}`
          ),
        ])
        .then(
          axios.spread((responseOne, responseTwo, responseThree) => {
            setTracklogOne(responseOne.data);
            setTracklogTwo(responseTwo.data);
            setTracklogThree(responseThree.data);
            setTableLoadingState(false);
          })
        );
    } else if (selectedTable.length === 2) {
      setTableLoadingState(true);
      axios
        .all([
          axios.get(
            // `https://serene-mountain-44627.herokuapp.com/https://tnb.urbanexplorer.com.my:7796/tracklogs/${selectedTable[0]}`
            `https://serene-mountain-44627.herokuapp.com/https://dbnsc.urbanstreetviewer.my/tracklogs/${selectedTable[0]}`
          ),
          axios.get(
            // `https://serene-mountain-44627.herokuapp.com/https://tnb.urbanexplorer.com.my:7796/tracklogs/${selectedTable[1]}`
            `https://serene-mountain-44627.herokuapp.com/https://dbnsc.urbanstreetviewer.my/tracklogs/${selectedTable[1]}`
          ),
        ])
        .then(
          axios.spread((responseOne, responseTwo) => {
            setTracklogOne(responseOne.data);
            setTracklogTwo(responseTwo.data);
            setTableLoadingState(false);
          })
        );
    } else if (selectedTable.length === 1) {
      setTableLoadingState(true);
      axios
        .get(
          // `https://serene-mountain-44627.herokuapp.com/https://tnb.urbanexplorer.com.my:7796/tracklogs/${selectedTable}`
          `https://serene-mountain-44627.herokuapp.com/https://dbnsc.urbanstreetviewer.my/tracklogs/${selectedTable}`
        )
        .then((response) => {
          setTracklogOne(response.data);
          setTableLoadingState(false);
        });
    }
  }, [selectedTable]);
  // console.log(selectedTable)

  useEffect(() => {
    fetchTracklog();
  }, []);

  return (
    <>
      <Menu stackable attached="top">
        <Menu.Item>
          <Icon name="grid layout" />
          <span style={{ padding: "0px 15px" }}>Grid</span>
          <Dropdown
            multiple
            selection
            closeOnChange

            value={selectedTable}
            options={table}
            onChange={handleTable}
            loading={tableLoadingState}

            placeholder="Select Grid"
          />
        </Menu.Item>
        <Menu.Item>
          <Icon name="search" />
          <span style={{ padding: "0px 15px" }}>Coordinate</span>
          <Form onSubmit={handleCoordinate}>
            <Form.Field required>
              <input
                placeholder="Coordinate"
                type="text"
                value={searchBox}
                onChange={(e) => setSearchBox(e.target.value)}
                minLength="5"
                required
              />
            </Form.Field>
          </Form>
        </Menu.Item>
        <Menu.Item>
          <Icon name="file outline" />
          <span style={{ padding: "0px 15.px" }}>Shape File</span>
          <Dropdown
            fluid
            // search
            selection
            placeholder="Select a Shape File"
            options={shapes}
            onChange={(event, { value }) => setShapeFile(value)}
          />
        </Menu.Item>
        <Menu.Item position="right">
          <Dropdown
            item
            simple
            direction="left"
            trigger={
              <div>
                <Icon name="user" />
                {currentUser.email}
              </div>
            }
          >
            <Dropdown.Menu>
              {isAdmin && (
                <Link to="/admin">
                  <Dropdown.Item>
                    <Icon name="setting" />
                    Admin Page
                  </Dropdown.Item>
                </Link>
              )}
              <Dropdown.Item onClick={handleLogout}>
                <Icon name="sign out" />
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu>

      <Grid stackable columns={2}>
        <Grid.Row style={{ paddingBottom: "0" }}>
          <Grid.Column
            computer={4}
            mobile={2}
            tablet={4}
            style={{ paddingRight: "0" }}
          >
            <Dimmer active={tableLoadingState}>
              <Loader>Fetching Tracklogs</Loader>
            </Dimmer>
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_FIREBASE_API_KEY}
              libraries={libraries}
            >
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "92.9vh",
                }}
                center={centerCoordinate}
                zoom={10}
                options={{
                  zoomControl: true,
                  mapTypeControl: true,
                  scaleControl: true,
                  streetViewControl: true,
                  rotateControl: true,
                  fullscreenControl: false,
                }}
                style={{}}
              >
                <KmlLayer
                  url={`https://serene-mountain-44627.herokuapp.com/https://storage.googleapis.com/images_ncs/KML%20Layer/${shapeFile}`}
                  options={{
                    screenOverlays: true,
                  }}
                />

                {/* {gridFive.map((item) => {
                  return (
                    <InfoBox
                      options={{
                        closeBoxURL: "",
                        enableEventPropagation: true,
                      }}
                      position={{
                        lat: item.lat,
                        lng: item.lng,
                      }}
                    >
                      <div
                        style={{
                          padding: 12,
                        }}
                      >
                        <div style={{ fontSize: 16, fontColor: "#000" }}>
                          {item.name}
                        </div>
                      </div>
                    </InfoBox>
                  );
                })} */}

                {tracklogOne.map((item) => {
                  return (
                    <Marker
                      key={item.filename}
                      onClick={(e) => {
                        let lat = e.latLng.lat();
                        let lon = e.latLng.lng();
                        getImage(tracklogOne, lat, lon);
                        setActiveTracklog(tracklogOne);
                        setCurrentCoordinate({
                          lat: lat,
                          lng: lon,
                        });
                      }}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude,
                      }}
                      icon={"images/solidCircleRed.png"}
                    />
                  );
                })}

                {tracklogTwo.map((item) => {
                  return (
                    <Marker
                      key={item.filename}
                      onClick={(e) => {
                        let lat = e.latLng.lat();
                        let lon = e.latLng.lng();
                        getImage(tracklogTwo, lat, lon);
                        setActiveTracklog(tracklogTwo);
                        setCurrentCoordinate({
                          lat: lat,
                          lng: lon,
                        });
                      }}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude,
                      }}
                      icon={"images/solidCircleGreen.png"}
                    />
                  );
                })}


                {tracklogThree.map((item) => {
                  return (
                    <Marker
                      key={item.filename}
                      onClick={(e) => {
                        let lat = e.latLng.lat();
                        let lon = e.latLng.lng();
                        getImage(tracklogThree, lat, lon);
                        setActiveTracklog(tracklogThree);
                        setCurrentCoordinate({
                          lat: lat,
                          lng: lon,
                        });
                      }}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude,
                      }}
                      icon={"images/solidCircleBlue.png"}
                    />
                  );
                })}

                {marker ? <Marker position={centerCoordinate} /> : ""}
                {/* {currentCoordinate ? ( 
                  <Marker
                    position={currentCoordinate}
                    options={{
                      icon: {
                        path: anSVGPathString,
                        rotation: currentRotationValue,
                      },
                    }}
                  />
                ) : (
                  ""
                )} */}

                {/* here the original dont play with it */}
                {/* { currentCoordinate && isGridExist < 1 ? ( 
                  <Marker
                    position={currentCoordinate}
                    options={{
                      icon: {
                        path: anSVGPathString,
                        rotation: currentRotationValue,
                      },
                    }}
                  />
                ) : currentCoordinate && isGridExist > 0 ? (
                  <Marker
                    position={currentCoordinate}
                    closeOnChange
                    
                  />
                ) : (
                  ""
                )}   */}



                {/* { setPosition ? (  //isGridExist > 0 /////////////////////////// here the original ///////////////
                  <Marker
                    position={ setPosition }
                    options={{
                      icon: {
                        path: anSVGPathString,
                        rotation: currentRotationValue,
                      },
                    }}
                    closeOnChange
                  />
                ) : setPosition ? ( //isGridExist < 1
                  <Marker  
                    position={ setPosition }
                    options={{
                      icon: {
                        path: anSVGPathString,
                        rotation: currentRotationValue,
                      },
                    }}
                  />
                ) : (
                  ""
                )}   */}


                {/* { setPosition ? (  //isGridExist > 0 /////////////////////////// here try the new idea ///////////////
                  <Marker
                    coordinate={setPosition}
                    flat 
                    anchor={{ x: 0.5, y: 0.5 }}
                    options={{
                      icon: {
                        path: anSVGPathString,
                        rotation: currentRotationValue,
                      },
                    }}
                    closeOnChange
                  />
                ) : setPosition ? ( //isGridExist < 1
                  <Marker  
                    position={ setPosition }
                    options={{
                      icon: {
                        path: anSVGPathString,
                        rotation: currentRotationValue,
                      },
                    }}
                  />
                ) : (
                  ""
                )}  */}

              </GoogleMap>
            </LoadScript>
          </Grid.Column>
          <Grid.Column
            computer={12}
            mobile={14}
            tablet={12}
            style={{ paddingLeft: "0" }}
          >
            <Pannellum
              ref={pannellumRef}
              width="100%"
              height="100%"
              image={`https://serene-mountain-44627.herokuapp.com/https://storage.googleapis.com/images_ncs/${gridId}/${gridId}-${imageNumber}.jpg`}
              // 182.1.1.23/project/path/to/images/ 
              autoLoad
            // onMouseup={(evt) => {
            //   let layerY = evt.layerY;
            //   if (layerY >= 360) {
            //   layerY = layerY - 360;
            //   setCurrentRotationValue(layerY);
            // } else {
            //   layerY = 360 - layerY;
            //   setCurrentRotationValue(layerY);
            // }
            // }}

            // onMouseup={(evt) => {
            //   let layerY = evt.layerY;
            //   if (layerY >= 360) {
            //   layerY = layerY - 360;
            //   setCurrentRotationValue(layerY);
            // } else {
            //   layerY = 360 - layerY;
            //   setCurrentRotationValue(layerY);
            // }
            // }}

            //   if ( isGridExist.length > 0 ) {

            //   } else {
            //     if (layerY >= 360) {
            //     layerY = layerY - 360;
            //     setCurrentRotationValue(layerY);
            //   } else {
            //     layerY = 360 - layerY;
            //     setCurrentRotationValue(layerY);
            //   }
            // }

            // if (layerY >= 360) {
            //   layerY = layerY - 360;
            //   setCurrentRotationValue(layerY);
            // } else {
            //   layerY = 360 - layerY;
            //   setCurrentRotationValue(layerY);
            // }
            />
            <Button
              circular
              inverted
              className="button-on-the-left" //button-on-the-left
              icon="angle up"
              onClick={getPreviousImage}

            />
            <Button
              circular
              inverted
              className="button-on-the-right" //button-on-the-right
              icon="angle down"
              onClick={getNextImage}

            />
            <Image
              className="logo-on-bottom-right"
              src={"images/icoUrbanStreetView.png"}
              size="small"
            />


          </Grid.Column>
        </Grid.Row>



      </Grid>
    </>
  );
};

export default MapScreen;
